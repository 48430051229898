body {
  background-color: #252525;
}

.loaderClass {
  border: 12px solid #dcd7d7;
  border-top: 12px solid #A698D3;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  opacity: .7;
  animation: SpinLoader 2.5s linear infinite;
}
.loaderClass img {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  animation: LogoModify 2.5s linear infinite;
}
@keyframes SpinLoader {
  0% {
     transform: rotate(0deg);
  }
  100% {
     transform: rotate(360deg);
  }
}
@keyframes LogoModify {
  0% {
     transform: rotate(360deg);
  }
  100% {
     transform: rotate(0deg);
  }
}